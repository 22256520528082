import './CSS/NotificationMessage.css'
import {RefreshButton} from "./Table";

export function NotificationMessage({info, setInfo, setNotification}) {
    if (!info.hasOwnProperty('msg')) {
        return <></>
    } else {
        let button = <></>
        if (info.hasOwnProperty('reloadButton') && info.reloadButton) {
            button = <RefreshButton setValues={setInfo} setNotification={setNotification}/>
        }
        return <div className={'notification'}>
            <div className={'notification_msg '+info.notificationType}>
                <span className={'notification_text'}>{info.msg}</span>
                {button}
            </div>
        </div>
    }

}