
export function GIcon({name, color, class_name}) {
    //let imgUrl = 'ancc.png'
    let cn = "md-18"
    if(class_name) {
        cn = class_name
    }
    if (color) {
        const colorStyle = {
            color: '' + color
        }
        return <i style={colorStyle} className={"material-icons " + cn}>{name}</i>
    } else {
        return <i className={"material-icons " + cn}>{name}</i>
    }
}
