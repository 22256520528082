import './CSS/Line.css';
import {useState} from "react";
import {DetailsTab} from "./DetailsTab";
import {GIcon} from "./GIcon";
import {GenericPopup} from "./GenericPopup";
import {silviodb_url} from "./index";

export function Line({count, info, setDownloadMessage, setNewChange}) {
    // The tab containing the system details and reports
    const [tabShown, toggleTab] = useState(false)
    //The confirmation popup status
    const [confirmationStatus, updateConfirmationStatus] = useState({})
    const showDetails = (e) =>  {
        toggleTab(!tabShown)
    }

    const fermooo = (e) => {
        e.stopPropagation()
    }

    const handleKeyPress = (e, myFunction) => {
        if (e.key === 'Enter') {
            myFunction(e)
        }
    }

    const dismissMessage = (e) => {
        updateConfirmationStatus({});
    }

    const setSnooze = (e) => {
        updateConfirmationStatus({'status': 'loading'});
        return fetch(silviodb_url+'/ignora?sn='+info['sn_new'])
            .then((response) => response.json())
            .then((r) => {
                setNewChange({'action': 'delete', 'item': info['sn_new']});
                updateConfirmationStatus({'status': 'success', 'deleted': info['sn_new']});
                console.log(r);
            })
            .catch((r) => {
                updateConfirmationStatus({'status': 'failed'});
                console.log(r);
            });
    }

    const setDelete = (e) => {
        updateConfirmationStatus({'status': 'loading'});
        return fetch(silviodb_url+'/elimina?sn='+info['sn_new'])
            .then((response) => response.json())
            .then((r) => {
                setNewChange({'action': 'delete', 'item': info['sn_new']});
                updateConfirmationStatus({'status': 'success', 'deleted': info['sn_new']});
                console.log(r);
            })
            .catch((r) => {
                updateConfirmationStatus({'status': 'failed'});
                console.log(r);
            });
    }

    const handleSnoozeButton = (e) => {
        let sn = e.currentTarget.dataset['sn'];
        let client = e.currentTarget.dataset['client'];
        e.stopPropagation();
        updateConfirmationStatus({
            'msg': 'Nascondere questa matricola?',
            'sub_msg': 'La matricola ' + sn + ' di ' + client + ' e le relative verifiche non appariranno più nella lista per i prossimi 30 giorni',
            'img': 'zzz.svg',
            'dismiss': dismissMessage,
            'buttons': [
                {'title': 'Annulla', 'action': dismissMessage},
                {'title': 'Conferma', 'action': setSnooze}
            ]
        });
    }

    const handleDeleteButton = (e) => {
        let sn = e.currentTarget.dataset['sn'];
        let client = e.currentTarget.dataset['client'];
        e.stopPropagation();
        updateConfirmationStatus({
            'msg': 'Cancellare questa matricola?',
            'sub_msg': 'La matricola ' + sn + ' di ' + client + ' e le relative verifiche verranno eliminate dalla lista.',
            'img': 'eraser.svg',
            'dismiss': dismissMessage,
            'buttons': [
                {'title': 'Annulla', 'action': dismissMessage},
                {'title': 'Elimina', 'action': setDelete}
            ]
        });
    }

    return (<>
            <div className={'line_container'}
                 tabIndex={0}
                 onClick={showDetails}
                 onKeyDown={(e) => handleKeyPress(e, showDetails)}
            >
                <span className={'line_num'}>{count}</span>
                <span className={'client_name'}>{info.client_name}</span>
                <span className={'city'}>{info.city} ({info.province})</span>
                <span className={'sn'}>{info.sn_new}</span>
                <span className={'type'}>[{info.system_type}] {info.system_info}</span>
                {detectExpiration(info)}
                {detectDates(info)}
                <div className={'buttons'}>
                    <button className={'my_button'}
                            onClick={fermooo}
                            onKeyDown={(e) => handleKeyPress(e, fermooo)}
                    >
                        <GIcon name={'edit_note'}/>
                        <span className={'button_text'}>Modifica</span>
                    </button>
                    <button className={'my_button'}
                            onClick={handleDeleteButton}
                            onKeyDown={(e) => handleKeyPress(e, fermooo)}
                            data-sn={info.sn_new}
                            data-client={info.client_name}
                    >
                        <GIcon name={'delete'}/>
                        Cancella </button>
                    <button className={'my_button'}
                            onClick={handleSnoozeButton}
                            onKeyDown={(e) => handleKeyPress(e, handleSnoozeButton)}
                            data-sn={info.sn_new}
                            data-client={info.client_name}
                    >
                        <GIcon name={'notifications_paused'}/>
                        Ignora 30gg
                    </button>
                </div>
            </div>
            <GenericPopup status={confirmationStatus}/>
            <DetailsTab showTab={tabShown} info={info} setDownloadMessage={setDownloadMessage}/>
        </>
    );
}

export function parseDate(date) {
    if (date) {
        let x = new Date(date)
        date = x.toLocaleDateString("en-gb")
    }
    return date
}

function detectExpiration(info) {
    const ret = []
    let expClass = 'ok'
    let text = 'Verifica tra '
    let days = info.day_left
    if (days < 0) {
        expClass = 'expired';
        days = days * -1;
        text = 'Scaduta da ';
    } else if (days < 30) {
        expClass = 'near';
    }
    ret.push(<span key={'days'+days+info.sn_new} className={'days '+expClass}>{text}<span className={'d2'}>{days}</span> gg</span>)
    ret.push(<span key={'label'+days+info.sn_new} className={'label'}>Ultima verifica</span>)
    ret.push(<span key={'verify_date'+days+info.sn_new} className={'verify_date'}>{parseDate(info.verify_date)}</span>)
    return ret
}

function detectDates(info) {
    let dateTypes = [
        {'label': "Periodica:", 'value': info.next_periodic},
        {'label': "Funzionamento:", 'value': info.next_functioning},
        {'label': "Integrità:", 'value': info.next_integrity},
        {'label': "Interna:", 'value': info.next_internal}
    ];
    let dateFiltered = []
    let out = []
    for (const dateType of dateTypes) {
        if (dateType['value']) {
            dateFiltered.push(dateType)
        }
    }
    let tot = dateFiltered.length
    let count = 0
    for (const dateType of dateFiltered) {
        let date = parseDate(dateType['value'])
        //Change status if verify is elapsed
        let status = ''
        let d = new Date(dateType['value'])
        if (isNaN(d)) {
            console.log("Error parsing date")
            console.log(d)
            console.log(date)
        }
        if (d < new Date()) {
            status = ' ver_elapsed'
        }

        count += 1;
        out.push(
            <span
                key={'l'+dateType['label']+info.sn_new}
                className={'l'+count +'_'+tot+' ver_t'}>
                {dateType['label']}
            </span>
        );
        out.push(
            <span
                key={'v'+dateType['label']+info.sn_new}
                className={'date'+count +'_'+tot+' date' + status}>
                {date}
            </span>
        );
    }
    return out
    ;
}