import './CSS/InsertPopup.css';

export function InsertPopup({info, updateInfo}) {

    if (info['asdasdas'] === 123) {
        updateInfo([])
    }
    if (info.length > 0) {
        return <div className={'insert_popup'}>
            <p>{info}</p>
        </div>
    } else {
        return <></>
    }

}