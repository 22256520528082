import './CSS/Table.css'
import {Line} from "./Line";
import {useEffect, useState} from "react";
import {SearchBox} from "./SearchBox";
import {SplitButton} from "./SplitButton";
import {InsertPopup} from "./InsertPopup";
import {silviodb_url} from "./index";
import {PageSection} from "./PageSection";
import {NotificationMessage} from "./NotificationMessage";
import {GIcon} from "./GIcon";
import {GenericPopup} from "./GenericPopup";

export function RefreshButton({setValues, setNotification}) {
    return <button onClick={(e) => {
        getReports()
            .then((fetchedReports) => updateReportInfo(fetchedReports, setValues, setNotification))
            .catch((e) => reportDownloadFailed(e, setNotification));
        setNotification({'notificationType': 'info_notif', 'msg': 'Aggiornamento...'})
    }
    }><GIcon name={'sync'}/></button>
}

function updateReportInfo(reports, setReports, setNotificationMessageInfo) {
    console.log("Reports fetched");
    console.debug(reports);
    setReports(reports);
    window.localStorage.setItem('reports', JSON.stringify(reports));
    setNotificationMessageInfo({});
}

function reportDownloadFailed(error, setNotification) {
    console.log(error)
    setNotification({'notificationType': 'error_notif', 'msg': 'Sei disconnesso - Funzionalità limitate', 'reloadButton': true, 'persistent': true});
}

function ReportsTable({lines}) {
    return <div className={'reports_table'}>
        <div className={'line_container header'}>
            <span>#</span>
            <span>Azienda</span>
            <span>Matricola</span>
            <span>Stato</span>
            <span className={'header_sc'}>Scadenze</span>
            <span >Azioni</span>
        </div>
        {lines}
    </div>;

}

export function Table() {
    // The list of all available reports
    const [reports, setReports] = useState([]);
    //Page number
    const [pageNumber, setPageNumber] = useState(1);
    // The searched text
    const [filterText, setFilterText] = useState('');
    // The system type filter
    const [systemTypeFilter, setSystemTypeFilter] = useState(['all']);
    // The days from the nearest deadline
    const [daysToDeadline, setDaysToDeadline] = useState(['all']);
    // Data to show on the insertion/modify popup
    const [popUpData, setPopData] = useState([]);
    // The download popup shown
    const [downloadMessage, setDownloadMessage] = useState({});
    // Define ordering
    const [order, setOrder] = useState(['next_verify']);
    // The notification message content
    const [notificationMessageInfo, setNotificationMessageInfo] = useState({})
    //Line changes
    const [change, setNewChange] = useState({})

    useEffect(() => {
        const r_promise = getReports();
        let tempReports = window.localStorage.getItem('reports');
        if (tempReports) {
            //Showing temporarly stored reports - if present
            setReports(JSON.parse(tempReports));
        } else {
            console.log("No local storage");
        }
        setNotificationMessageInfo({'notificationType': 'info_notif', 'msg': 'Caricamento...'});
        r_promise
            .then((fetchedReports) => updateReportInfo(fetchedReports, setReports, setNotificationMessageInfo))
            .catch((e) => reportDownloadFailed(e, setNotificationMessageInfo));

    }, []);

    //console.log(info)
    const lines = [];
    //Apply update
    let tempList = applyUpdate(reports, change, setNewChange);
    //Apply filters
    tempList = filterByType(tempList, systemTypeFilter);
    tempList = filterBySearch(tempList, filterText);
    tempList = filterByDeadline(tempList, daysToDeadline)
    //Apply ordering
    tempList = orderList(tempList, order);
    console.log("Showing "+tempList.length+ " elements")
    //Create lines
    let count = 0
    for (const infoElement of tempList) {
        count += 1;
        let key = infoElement['sn_new']
        // Limiting print to first n results
        let ppl = process.env.REACT_APP_LINES_PER_PAGE
        if (count > ppl*(pageNumber-1) && count < ppl*pageNumber) {
            lines.push(
                <Line
                    key={key}
                    count={count}
                    info={infoElement}
                    setDownloadMessage={setDownloadMessage}
                    setNewChange={setNewChange}
                >
                </Line>
            )
        }
    }
    if (lines.length === 0) {
        lines.push(
            <div className={'nothing_found'} key={'None'}>
                <img src={'search.svg'} alt={'Nessun risultato'}/>
                <span>Non ho trovato niente</span>
                <span className={'nothing_suggest'}>Prova a rimuovere qualche filtro</span>
            </div>)
    } else {
        //console.log("ci sono " + lines.length + " linee")
    }
    //<p>Ciao questa è la mia lista. Clicca per aggiornare <RefreshButton setValues={setReports}/></p>
    return (
        <div>
            <NotificationMessage
                info={notificationMessageInfo}
                setInfo={setReports}
                setNotification={setNotificationMessageInfo}
            />
            <div className={'interactiveSection'}>
                <SearchBox
                    text={filterText}
                    onFilterTextChange={setFilterText}
                />
                <div className={'button_groups'}>
                    <SplitButton
                        title={['Attrezzature']}
                        names={[['GVR', 'SC/SP', 'Tutto']]}
                        multipleChoice={false}
                        values={[[['GVR'], ['SC','SP'], ['all']]]}
                        setOrdering={setSystemTypeFilter}
                        currentOrderingValues={systemTypeFilter}
                    />
                    <SplitButton
                        title={['Scadute da meno di', 'In scadenza entro']}
                        names={[['2 anni', '1 anno', '6 mesi'], ['30 giorni', '60 giorni', 'Tutto']]}
                        multipleChoice={false}
                        values={[[[-730], [-365], [-180]], [[30], [60], ['all']]]}
                        setOrdering={setDaysToDeadline}
                        currentOrderingValues={daysToDeadline}
                    />
                    <SplitButton
                        title={['Ordina per']}
                        names={[['Scadenza', 'Ultima verifica', 'Cliente']]}
                        multipleChoice={false}
                        values={[[['next_verify'],['report_date'],['client_name']]]}
                        setOrdering={setOrder}
                        currentOrderingValues={order}
                    />
                </div>
                <PageSection
                    pageNumber={pageNumber}
                    allValues={tempList}
                    setPageNumber={setPageNumber}
                />
            </div>
            <InsertPopup
                info={popUpData}
                updatePopUp={setPopData}
            ></InsertPopup>
            <GenericPopup status={downloadMessage}/>

            <ReportsTable lines={lines}></ReportsTable>

        </div>
    );
}

function getReports(orderedBy = 'data') {
    return fetch(silviodb_url+'/verifiche')
        .then((response) => {
            return response.json()
        });
}


function applyUpdate(reports, change, setNewChange) {
    if (change.hasOwnProperty('action') && change.hasOwnProperty('item')) {
        let index = detectElement(reports, change['item']);
        if (index > -1) {
            if (change['action'] === 'delete') {
                console.log("Deleting " + change['item']);
                reports.splice(index, 1);
            } else if (change['action'] === 'modify') {
                console.log("Modifing " + change['item']);
            } else {
                console.error("Unknown error");
            }
        } else {
            console.log("Element not found: " + change['item'])
            //Remove news - Not setting
            setNewChange({});
        }

    }
    return reports;
}

function detectElement(reports, key) {
    for (let i = 0; i < reports.length; i++) {
        if (reports[i]['sn_new'] === key) {
            return i
        }
    }
    return -1
}

/**
 * Filter reports by their verify type
 * @param {array} data The list of all the reports
 * @param {array} systemsToShow The chosen reports type
 * @returns {array} The filtered list
 */
function filterByType(data, systemsToShow) {
    if (systemsToShow.includes('all')) {
        // No filter set done - Return the entire initial set
        return data;
    } else {
        let elaboratedData = []
        for (const datum of data) {
            //Check if the system type is defined
            if (datum['system_type']) {
                for (let s of systemsToShow) {
                    s = s.toLowerCase().trim()
                    if (datum['system_type'].toLowerCase().trim() === s) {
                        elaboratedData.push(datum);
                        break
                    }
                }
            }
        }
        return elaboratedData;
    }
}

function filterByDeadline(data, systemsToShow) {
    if (systemsToShow.includes('all')) {
        // No filter set done - Return the entire initial set
        return data;
    } else {
        let elaboratedData = []
        for (const datum of data) {
            let days_left = datum['day_left'];
            //Check if the data is greater is defined
            if (days_left) {
                for (let filterDays of systemsToShow) {
                    if (filterDays < 0) {
                        if (days_left > filterDays && days_left <= 0) {
                            elaboratedData.push(datum);
                            break
                        }
                    } else {
                        if(days_left >= 0 && days_left <= filterDays) {
                            elaboratedData.push(datum);
                            break
                        }
                    }

                }
            }
        }
        return elaboratedData;
    }
}

/**
 * Filter reports using a user query
 * @param {array} data The list of all the reports
 * @param {string} search The searched text type
 * @returns {array} The list of filtered reports
 */
function filterBySearch(data, search) {
    let filterSet = search.trim().toLowerCase().replace(/[^a-z0-9]/g, '');
    console.log("Filtro: "+filterSet)
    const interestedFields = ['client_name', 'city', 'sn_new']
    if (!filterSet) {
        // No search done - Return the entire initial set
        return data;
    } else {
        let elaboratedData = []
        for (const datum of data) {
            for (const field of interestedFields) {
                if (datum[field] && datum[field].toLowerCase().replace(/[^a-z0-9]/g, '').includes(filterSet)) {
                    elaboratedData.push(datum)
                    break;
                }
            }
        }
        return elaboratedData;
    }
}


/**
 * Given a criteria it orders elements ordering
 * @param {Object} a The first element to compare
 * @param {Object} b The second element to compare
 * @param {[{'name': string}, {'order': Integer}]} criteria The criteria to use in order to validate elements
 * @returns {0|1|-1} The ordering outcome
 */
function ordering(a, b, criteria) {
    for (const criterion of criteria) {
        let field_name = criterion.name;
        let sort_ordering = criterion.order;
        if (a[field_name] > b[field_name]) {
            return sort_ordering;
        } else if (a[field_name] < b[field_name]) {
            return sort_ordering * -1;
        }
    }
    console.log("No ordering done")
    //Same values - Ordering not important
    return 0;
}

function orderList(tempList, order) {
    const orderByNext = (a, b) => {
        return ordering(a, b, [
            {'name': 'day_left', 'order': 1},
            {'name': 'client_name', 'order': 1},
            {'name': 'city', 'order': 1},
            {'name': 'sn_new', 'order': 1},
        ]);
    }

    const orderByVerify = (a, b) => {
        //console.log("A [" + a['verify_date'] +"] vs B [ "+b['verify_date']+" ]");
        return ordering(a, b, [
            {'name': 'verify_date', 'order': -1},
            {'name': 'client_name', 'order': 1},
            {'name': 'city', 'order': 1},
            {'name': 'sn_new', 'order': 1},
        ]);
    }
    const orderByClient = (a, b) => {
        return ordering(a, b, [
            {'name': 'client_name', 'order': 1},
            {'name': 'city', 'order': 1},
            {'name': 'sn_new', 'order': 1},
        ]);
    }
    console.log(tempList)
    //Check which
    if (order[0] === 'next_verify') {
        return tempList.sort(orderByNext);
    } else if (order[0] === 'report_date') {
        return tempList.sort(orderByVerify);
    } else if (order[0] === 'client_name') {
        return tempList.sort(orderByClient);
    }
    console.log("Not applying any sorting")
    return tempList;
}