import './CSS/GenericPopup.css';
import {GIcon} from "./GIcon";

export function GenericPopup({status}) {
    if (status.hasOwnProperty('msg')) {
        return <div className={'download_message_div'}
                    onClick={(e) => status.dismiss(e)}
            >
                    <div className={'download_group'} onClick={(e) => e.stopPropagation()}>
                        <span className={'close_message'}
                              onClick={(e) => status.dismiss(e)}
                        >
                            <GIcon name={'close'}/>
                        </span>
                    <img src={status.img} alt={status.img}></img>
                    <span className={'download_message'}>{status.msg}</span>
                    <span className={'download_sub_message'}>{status.sub_msg ?? ''}</span>
                    <div className={'button_section'}>{generateButtons(status.buttons) ?? ''}</div>
            </div>
        </div>
    }

}

function generateButtons(buttons) {
    if (!buttons) {
        buttons = []
    }
    const b_list = []
    for (const button of buttons) {
        b_list.push(<button
            key={button.title}
            className={'confirm_button'}
            onClick={button.action}
        >
            {button.title}
        </button>);
    }
    return b_list;
}