import './CSS/SearchBox.css';

export function SearchBox({text, onFilterTextChange}) {
    let timeout;
    const doSearch = (evt) => {
        let searchText = evt.target.value; // this is the search text
        if(timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            console.log("Update status")
            onFilterTextChange(searchText)
        }, 300);
    }

    return <input
        autoFocus
        type="text"
        className={'searchbox'}
        placeholder="Cerca cliente o matricola"
        //value={text}
        onChange={(e) => doSearch(e) }/>
}