import './CSS/PageSection.css'

export function PageSection({pageNumber, allValues, setPageNumber}) {
    const total_lines = allValues.length
    const tot_pages =  Math.ceil(total_lines / process.env.REACT_APP_LINES_PER_PAGE);
    const last_rows = total_lines % process.env.REACT_APP_LINES_PER_PAGE
    const lines_in_this_page = (total_lines === 0 || pageNumber === tot_pages) ? last_rows : process.env.REACT_APP_LINES_PER_PAGE;
    if (pageNumber > tot_pages && pageNumber !== 1) {
        setPageNumber(1);
        console.log("Resetting to first page ["+pageNumber+">"+tot_pages+"]");
    }
    const page_to_print = total_lines ? pageNumber : 0;

    return <div className={'page_section'}>
        <span>
            {'Pagina '+ page_to_print + " di " + tot_pages}
        </span>
        <div>
            {insertPageButton(pageNumber, setPageNumber, tot_pages)}
        </div>
        <span>
            {'Visualizzate '+lines_in_this_page+'/'+allValues.length}
        </span>
    </div>
}

function insertPageButton(pageNumber, setPageNumber, tot_pages) {
    const buttons = []
    for (let i = 0; i < tot_pages; i++) {
        let selected = ((i+1 === pageNumber) ? ' p_selected' : '');
        buttons.push(
            <button
                key={i}
                className={'page_button '+selected}
                value={i+1}
                onClick={(e) => setPageNumber(parseInt(e.currentTarget.value))}
            >{i+1}</button>
        );
    }
    return buttons
}