import './CSS/App.css';
import {Table} from "./Table";
import {TitleBar} from "./TitleBar";

function App() {
    return (
        <>
            <TitleBar/>
            <div className="App">
              <header className="App-header">
                  <Table/>
              </header>
            </div>
        </>
);
}

export default App;
