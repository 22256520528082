import './CSS/SplitButton.css';

export function SplitButton({title, names, multipleChoice, values, setOrdering, currentOrderingValues}) {
    let group_num = Math.min(names.length, values.length)
    const buttons_group = []
    let span_key = ''
    for (let i = 0; i < group_num; i++) {
        let buttons = []
        let tot = Math.min(names[i].length, values[i].length)
        for (let j = 0; j < tot; j++) {
            //Elaborate button class
            let buttonClass = 'spb_center'
            if (j === 0) {
                buttonClass = 'spb_left'
            } else if (j === tot-1) {
                buttonClass = 'spb_right'
            }
            //Elaborate span key
            let n = names[i][j];
            let v = values[i][j];
            span_key += n;

            buttons.push(
                <ToggleButton
                    key={v}
                    className={buttonClass}
                    name={n}
                    value={v}
                    multipleChoice={multipleChoice}
                    setOrdering={setOrdering}
                    currentValues={currentOrderingValues}
                />
            )
        }
        buttons_group.push(<div key={span_key} className={'toggle_btn_group'}>
            <span className={'button_title'}>{title[i]}</span>
            <div className={'button_container'}>
                {buttons}
            </div>
        </div>)
    }

    return buttons_group
}

/**
 *
 * @param {string} name The name of the pressed button
 * @param {[string]} value The list of new values to process
 * @param {boolean} multipleChoice True if multiple values can be selected
 * @param setOrdering The function to set the new status
 * @param {[string]} currentValues The current status of toggle buttons
 * @param {string} className The class of the button depending on its positioning
 * @returns {JSX.Element}
 * @constructor
 */
function ToggleButton({name, value,multipleChoice, setOrdering, currentValues, className}) {
    // The list of all available reports
    let status = ''
    if (currentValues.includes(value[0])) {
        status = ' pressed'
    }
    return <button
        className={className+status}
        onClick={(e) => updateOrdering(value, setOrdering, currentValues, multipleChoice)}>
        {name}</button>
}

/**
 * Updates the ordering based on system types
 * @param {[string]} value The new values to apply
 * @param setOrdering The function to update current status
 * @param {[string]} currentValues The current status
 * @param {boolean} multipleChoice True if multiple values can be selected
 */
function updateOrdering(value, setOrdering, currentValues, multipleChoice) {
    if (!multipleChoice) {
        setOrdering(value)
        return
    }
    const newVal = [...currentValues]
    //Process click values
    for (const v of value) {
        if (newVal.includes(v)) {
            //Remove value if present
            console.log("Removing")
            console.log(v)
            let index = newVal.indexOf(v);
            if (index > -1) {
                newVal.splice(index, 1);
            }
        } else {
            if (v === 'all') {
                //Insert only all
                newVal.length = 0
                newVal.push(v)
                break
            }
            //Add value if missing
            console.log("Adding")
            console.log(v)
            newVal.push(v)
        }
    }
    console.log("Old value")
    console.log(currentValues)
    console.log("New value")
    console.log(newVal)

    //Update fields to show values
    if (newVal.length === 0) {
        //If nothig is left set to all
        newVal.push('all')
    } else if (newVal.length >= 2) {
        //Remove all if there is a different selection
        console.log("Rimuovo all")
        let index = newVal.indexOf('all');
        if (index > -1) {
            newVal.splice(index, 1);
        }
    }
    //Update state
    setOrdering(newVal)
}