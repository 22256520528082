import './CSS/DetailsTab.css';
import {useState} from "react";
import {parseDate} from "./Line";
import {silviodb_url} from "./index";
import {GIcon} from "./GIcon";

export function DetailsTab({info, showTab, setDownloadMessage}) {
    //The list of reports to show
    const [reports, setReports] = useState([]);

    if (showTab) {
        let row_company = ""
        let row_contact = ""
        const evaluate = (field, prepend = '', append = '', company = true) => {
            if (info[field]) {
                if (company) {
                    row_company += prepend + info[field] + append;
                } else {
                    row_contact += prepend + info[field] + append;
                }
            }
        }
        const generateReportLines = () => {
            const lines = []
            for (const infoElement of reports) {
                lines.push(generateReportLine(infoElement, setDownloadMessage));
            }
            return lines;
        }


        evaluate('client_name', '🏭 ',' - ')
        evaluate('city', '🌍 ',' ')
        evaluate('province', '(', ') ')
        evaluate('street', ' - ','')
        evaluate('street_number', ' ','')

        evaluate('contact_person', '👨‍💼 ', ' - ', false)
        evaluate('phone', '📞 ', '', false)

        if (reports.length < 1) {
            console.log("Chiedo i report")
            fetch(silviodb_url+'/verbali?sn='+info['sn_new'])
                .then((response) => response.json())
                .then((r) => {
                    setReports(r)
                })
        }

        return <div className={'detail_container'}>
            <div>
                <span className={'subtitle'}>Informazioni cliente</span>
                <span className={'detail_info'}>{row_company}</span>
                <span className={'detail_info'}>{row_contact}</span>
            </div>
            <div>
                <span className={'subtitle'}>Ultimi verbali</span>
                <div className={'report_list'}>
                    <span className={'report_line rl_header'}>
                        <span>File</span>
                        <span>Tipo</span>
                        <span>Data verifica</span>
                        <span>Nome file</span>
                        <span>Verificatore (Affiancamento)</span>
                    </span>
                    {generateReportLines(reports)}
                </div>
            </div>
        </div>
    }
}



function generateReportLine(infoElement, setDownloadMessage) {
    let sn = infoElement['sn']
    let date = infoElement['verify_date']
    let type = infoElement['verify_type']
    let src = infoElement['report_source']
    let img = getIcon(src);
    let ver_t = getVerType(type)
    date = parseDate(date)
    let fn = parseFilename(infoElement['file_name'])
    let verifier = parseVerifier(infoElement)

    let key = String(sn ?? '') + String(date ?? '') + String(type ?? '') + String(src ?? '')
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            requestDownload(e, setDownloadMessage)
        }
    }

    return <span key={key} className={'report_line'}>
                <img
                    className={'doc_icon'}
                    src={img}
                    alt={''}
                    data-sn={sn}
                    data-date={infoElement['verify_date']}
                    data-verifytype={type}
                    data-source={src}
                    tabIndex={0}
                    onClick={(e) => requestDownload(e, setDownloadMessage)}
                    onKeyDown={handleKeyPress}
                ></img>
                <span className={'ver_type'}>{ver_t}</span>
                <span className={'date'}>{date}</span>
                <span className={'filename'}>{fn}</span>
                <span className={'verifier'}>{verifier}</span>
            </span>
}

function getIcon(src) {
    let img = 'xls.svg'
    if (src === 'word_report') {
        img = 'doc.svg'
    } else if (src === 'pdf_report') {
        img = 'pdf.svg'
    }
    return img
}

function getVerType(type) {
    let text = '';
    let translation = [
        {'code': 'ODS', 'icon': 'close', 'color': 'red', 'ita': 'Lista verifiche'},
        {'code': 'PVP', 'icon': 'looks_one', 'color': 'blue', 'ita': 'Prima Periodica'},
        {'code': 'First', 'icon': 'looks_one', 'color': 'blue', 'ita': 'Prima Periodica'},
        {'code': 'VP', 'icon': 'update', 'color': 'green', 'ita': 'Periodica'},
        {'code': 'SC', 'icon': 'update', 'color': 'green', 'ita': 'Periodica'},
        {'code': 'SP', 'icon': 'update', 'color': 'green', 'ita': 'Periodica'},
        {'code': 'Periodic', 'icon': 'update', 'color': 'green', 'ita': 'Periodica'},
        {'code': 'Internal', 'icon': 'center_focus_strong', 'color': 'blue', 'ita': 'Interna'},
        {'code': 'Integrity', 'icon': 'health_and_safety', 'color': 'black', 'ita': 'Integrità'},
        {'code': 'Functioning', 'icon': 'construction', 'color': 'green', 'ita': 'Funzionamento'},
    ]
    if (type) {
        //Evaluate a manual translation based on the given mapping
        for (const t of translation) {
            if (type.trim().toLowerCase() === t['code'].toLowerCase()) {
                return <><GIcon name={t['icon']} color={t['color']}></GIcon>{t['ita']}</>
            }
        }
        text = type;
    } else {
        text = "N/A";
    }
    return text;
}

function parseFilename(filename) {
    if (!filename) {
        filename = '(Solo in lista verifiche)'
    }
    return filename
}

function parseVerifier(infoElement) {
    let verifier = '';
    let v_name = infoElement['verifier']
    let a_name = infoElement['supported']
    if (v_name) {
        if (v_name.toLowerCase().includes('seri')) {
            verifier = "Seri"
        }
    } else {
        verifier = 'N/A'
    }
    if (a_name) {
        verifier += ' ('+a_name+')'
    }
    return verifier;
}

function requestDownload(e, setDownloadMessage) {
    let sn = e.currentTarget.dataset.sn;
    let date = e.currentTarget.dataset.date;
    let verifytype = e.currentTarget.dataset.verifytype;
    let src = e.currentTarget.dataset.source;
    const dismissMessage = () => {setDownloadMessage({})};
    fetch(silviodb_url+'/verbali/download?sn='+sn+'&date='+date+'&type='+verifytype+'&source='+src,{cache: "reload"})
        .then(e => {
            console.log(silviodb_url+'/verbali/download?sn='+sn+'&date='+date+'&type='+verifytype+'&source='+src)
            const r_type = e.headers.get('Content-type');
            console.log("Type:")
            console.log(r_type)
            if (r_type.includes('json')) {
                setDownloadMessage({
                    'img': 'xls.svg',
                    'msg': 'Report non disponibile',
                    'dismiss': dismissMessage
                });
                console.log("Cannot download");
            } else {
                const f_name = e.headers.get('Content-Disposition').split(';', 2)[1].split('"')[1];
                e.blob().then((f) => {
                    downloadReport(f, f_name)
                    setDownloadMessage({
                        'img': 'ok.svg',
                        'msg': 'Report scaricato',
                        'dismiss': dismissMessage
                    });
                });
                setDownloadMessage({
                    'img': 'download.svg',
                    'msg': 'Sto scaricando il report',
                    'dismiss': dismissMessage
                });
            }
        });
}

function downloadReport(blob, name) {
    console.log(blob)
    const url = window.URL.createObjectURL(new Blob([blob]));
    console.log(url)
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = name;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
}